import React from 'react';
import './ArticleList.css';
import {ArticleMetadata} from "../App";
import 'material-icons/iconfont/material-icons.css';


interface ArticleListProps {
    articles: ArticleMetadata[];
}

export const ArticleList: React.FC<ArticleListProps> = ({ articles }) => {
    console.log(articles);
    return (
        <div className="article-list">
            {articles.map((article) => (
                <div key={article.title} className="article-preview content-item" onClick={() => {window.location.href = `/article/${article.title}`}}>
                    <a href={`/article/${article.title}`}>
                        <h2 className="article-title">
                            <span className="material-icons">article</span>
                            {article.title}
                        </h2>
                    </a>
                    <div className="article-excerpt">
                        <p>
                            {article.content}
                        </p>
                    </div>

                    <div className="article-actions">
                        <div className="reading-time-container">
                            <span className="material-icons">timer</span>
                            <p className="reading-time">
                                {article.readingTimeMinutes} min read
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

