import React, {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm'
import { Link as ScrollLink } from 'react-scroll';
import { InView } from 'react-intersection-observer';
import './Article.css';
import {useParams} from "react-router-dom";

type Header = {
    level: number;
    text: string;
}

export const Article = () => {
    let [markdown, setMarkdown] = useState('');
    let [headers, setHeaders] = useState([] as Header[]);
    let [activeStepIndex, setActiveStepIndex] = useState(0);
    let [indicesInView, setIndicesInView] = useState([] as boolean[]);
    let { articleId } = useParams();

    useEffect(() => {
        (async () => {
            console.log('Attempting to fetch ' + articleId);
            if (articleId) {
                try {
                    const myMarkdown = await fetch('https://trendmagnet.net/' + encodeURIComponent(articleId + ".md"));
                    const myText = await myMarkdown.text();
                    if (!myText.includes('<head><title>404 Not Found</title></head>')) {
                        setMarkdown(myText);
                        extractHeaders(myText);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        })();
    }, [articleId]);

    const extractHeaders = (markdownText: string) => {
        const headerRegex = /(^|\n)(#+)\s*(.+?)(\n|$)/g;
        let headersList = [];
        let match;

        while ((match = headerRegex.exec(markdownText)) !== null) {
            headersList.push({
                level: match[2].length,
                text: match[3],
            });
        }

        setHeaders(headersList);
    };

    // @ts-ignore
    const handleIntersection = (inView, entry) => {
        const headerText = entry.target.getAttribute('data-header');
        const index = headers.findIndex((header) => header.text === headerText);
        if (index >= indicesInView.length) {
            for (let i = indicesInView.length; i <= index; i++) {
                indicesInView.push(false);
            }
        }
        indicesInView[index] = inView;
        setIndicesInView(indicesInView);

        if (inView) {
            if (index < activeStepIndex) {
                setActiveStepIndex(index);
            }
        } else {
            for (let i = 0; i < indicesInView.length; i++) {
                if (indicesInView[i]) {
                    setActiveStepIndex(i);
                    break;
                }
            }
        }
    };

    return (
        <div className="article-container">
            <div className="toc-container">
                <div className="header-list">
                    <ul>
                        {headers.map((header, index) => (
                            <li key={index}>
                                <ScrollLink
                                    to={header.text}
                                    smooth={true}
                                    duration={500}
                                    offset={-120}
                                    spy={true}
                                    style={{
                                        display: 'block',
                                        padding: '0.5rem 1rem',
                                        fontWeight: activeStepIndex === index ? '600' : '300',
                                        backgroundColor:
                                            activeStepIndex === index ? '#0085f230' : 'white',
                                    }}
                                >
                                    {header.text}
                                </ScrollLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="content-item">
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={markdown}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ node, ...props }) => (
                            <InView as="div" onChange={handleIntersection} name={props.children.join('')} data-header={props.children.join('')}>
                                <h1 {...props} />
                            </InView>
                        ),
                        h2: ({ node, ...props }) => (
                            <InView as="div" onChange={handleIntersection} name={props.children.join('')} data-header={props.children.join('')}>
                                <h2 {...props} />
                            </InView>
                        ),
                        h3: ({ node, ...props }) => (
                            <InView as="div" onChange={handleIntersection} name={props.children.join('')} data-header={props.children.join('')}>
                                <h3 {...props} />
                            </InView>
                        ),
                        h4: ({ node, ...props }) => (
                            <InView as="div" onChange={handleIntersection} name={props.children.join('')} data-header={props.children.join('')}>
                                <h4 {...props} />
                            </InView>
                        ),
                        h5: ({ node, ...props }) => (
                            <InView as="div" onChange={handleIntersection} name={props.children.join('')} data-header={props.children.join('')}>
                                <h5 {...props} />
                            </InView>
                        ),
                        h6: ({ node, ...props }) => (
                            <InView as="div" onChange={handleIntersection} name={props.children.join('')} data-header={props.children.join('')}>
                                <h6 {...props} />
                            </InView>
                        ),
                    }}
                />
            </div>
        </div>
    );
};
