import styled from '@emotion/styled';

const FooterWrapper = styled.div`
  background-color: black;
  color: white !important;
  text-align: center;
  width: 100%;
  margin-top: auto;
`;

export const Footer = () => {
    return (
        <FooterWrapper>
            <p style={{color: 'white'}}>&copy; {new Date().getFullYear()} Trendmagnet. All rights reserved.</p>
        </FooterWrapper>
    );
};
