// Import styled from @emotion/styled
import styled from '@emotion/styled';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: black;
  padding: 1rem 2rem;
  color: black;
  flex-direction: column;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  max-height: 50px - 1rem;
  padding: 10px;
  z-index: 100000;
`;

const SiteTitle = styled.div`
  margin: 0;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 50px;
  width: 208px;
  margin-bottom: 0.5rem;
  margin: 0;
`;

export const Header = () => {
    return (
        <HeaderWrapper>
            <SiteTitle>
                <a href='/'>
                    <Logo src="/trendmagnet.svg" alt="Main page" />
                </a>
            </SiteTitle>
        </HeaderWrapper>
    );
};
