import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Header} from './components/Header';
import {Footer} from './components/Footer';
import {ArticleList} from './components/ArticleList';
import {Article} from './components/Article';
import './App.css';
import {Adsense} from '@ctrl/react-adsense';
import {useEffect, useState} from "react";


export type ArticleMetadata = {
    title: string;
    content: string;
    readingTimeMinutes: number;
    publishedDate: string;
}



function ads() {
    return (
        <div className="ad-slot">
            {/*<p>I am an ad</p>*/}
            <Adsense
                client='ca-pub-9617997244531331'
                slot='8497423537'
                style={{ display: 'block' }}
                format='fluid'
            />
        </div>
    )
}

function App() {
    const [articles, setArticles] = useState<ArticleMetadata[]>([]);

    type StringType = {
        S: string;
        N: never;
    }

    type NumberType = {
        S: never;
        N: string;
    }

    type DynamoItem = {
        [column in keyof ArticleMetadata]: StringType | NumberType;
    };

    useEffect(() => {
        const dynamoToArr = (dynamoRes: { Items: DynamoItem[]}): ArticleMetadata[] => {
            return dynamoRes['Items'].map((item) => {
                const columns = Object.keys(item);
                const reducer = (newItem: ArticleMetadata, column: string) => {
                    const col = column as keyof ArticleMetadata;
                    if (item[col].S !== undefined) {
                        // @ts-ignore
                        newItem[col] = item[col].S;
                    } else {
                        // @ts-ignore
                        newItem[col] = parseFloat(item[col].N);
                    }
                    return newItem;
                }
                return columns.reduce<ArticleMetadata>(reducer, {} as ArticleMetadata) as unknown as ArticleMetadata;
            }).sort((a,b) => b.publishedDate.localeCompare(a.publishedDate));
        }

        const getArticleMetadata = async () => {
            setArticles(dynamoToArr((await (await fetch('https://api.trendmagnet.net/article-metadata')).json())));
        }

        getArticleMetadata();
    }, []);

    return (
        <Router>
            <Header/>
            <script async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9617997244531331"
                    crossOrigin="anonymous"/>
            <div className="wrapper">
                <div className="ad-container left">
                    {ads()}
                </div>
                <div className='content-wrapper'>
                    <Routes>
                        <Route path="/" element={<ArticleList articles={articles}/>}/>
                        <Route path="/article/:articleId" element={<Article/>}/>
                    </Routes>
                </div>
                <div className="ad-container right">
                    {ads()}
                </div>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
